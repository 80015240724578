import {
  createContext,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import useApi from "../hooks/useApi";
import { useDebouncedCallback } from "use-debounce";
import storage from "../storage";

export interface AppContextProps {
  isAuthenticated: boolean;
  user: any;
  setToken: (token: string) => void;
  logout: () => void;
}

export const AppContext = createContext<AppContextProps>({
  isAuthenticated: false,
  user: {},
  setToken: () => {},
  logout: () => {},
});

const AppProvider: React.FC<any> = memo(({ children }) => {
  const api = useApi();

  const [isAuthenticated, setIsAuthenticated] = useState(
    !!storage.getItem("access_token"),
  );

  const [user, setUser] = useState<any>(storage.getItem("user") ?? {});

  const setToken = useCallback((token: string) => {
    storage.setItem("access_token", token);
    setIsAuthenticated(!!storage.getItem("access_token"));
  }, []);

  const logout = useCallback(() => {
    api.logout().then(() => {
      storage.removeItem("user");
      storage.removeItem("access_token");
      setIsAuthenticated(false);
      setUser({});
    });
  }, [api]);

  const loadUser = useDebouncedCallback(async () => {
    api
      .getUser()
      .then((response) => {
        setUser(response.data ?? {});
        storage.setItem("user", JSON.stringify(response.data));
      })
      .catch(() => {
        storage.removeItem("user");
        storage.removeItem("access_token");
        setIsAuthenticated(false);
        setUser({});
      });
  });

  useEffect(() => {
    if (isAuthenticated) {
      loadUser();
    }
  }, [isAuthenticated, loadUser]);

  const value = useMemo(
    () => ({
      isAuthenticated,
      user,
      setToken,
      logout,
    }),
    [isAuthenticated, user, setToken, logout],
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
});

export default AppProvider;
