import { AxiosError, AxiosResponse } from 'axios'
import { IResponse } from '../interfaces';

export class Response {
  static from<T = any>(response: AxiosResponse<IResponse>): Promise<T>{
    if (response?.data?.success === true) {
      return Promise.resolve(response.data as T);
    }

    if (response?.data?.success === false) {
      return Promise.reject(response.data as T);
    }

    throw response;
  }

  static fromError<T = any>(error: AxiosError<any>): Promise<T>{
    return Promise.reject({
      success: false,
      message: error.response?.data.message ?? error.message,
      data: [],
    });
  }
}
