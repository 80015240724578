import App from "./App.tsx";
import { createRoot } from "react-dom/client";
import { pdfjs } from 'react-pdf';
import "./sass/style.scss";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(<App />);
