import { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import * as CookieConsent from "vanilla-cookieconsent";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import LogoFooterImage from "../assets/images/images/footer-logo.png";
import cookieConfig from "../configs/cookieConfig";

export default function Footer() {
  useEffect(() => {
    CookieConsent.run(cookieConfig);
  }, []);

  return (
    <footer id="fale-conosco">
      <Container>
        <Row>
          <Col xs={12} md={6}>
            <Image width={200} src={LogoFooterImage} />
          </Col>

          <Col
            xs={12}
            md={6}
            className="text-center text-lg-start mt-5 mb-4 mt-lg-0"
          >
            <span className="d-block fs-5 mb-3">FALE CONOSCO</span>

            <p>
              E-mail:{" "}
              <a href="mailto: contato@promocao120horas.com.br">
              contato@promocao120horas.com.br
              </a>
              <br />
              <br />  
              Dúvidas, fale com a nossa equipe
              <br />
              horário de atendimento de segunda a sexta-feira, das 08:00 às 18:00
              <br />
              sua mensagem será respondida em até 72 horas úteis
            </p>

            <div className="d-flex flex-row justify-content-around justify-content-lg-start align-items-center">
              <NavLink
                className="text-lg-start"
                to="/politica-privacidade"
              >
                POLÍTICA DE PRIVACIDADE
              </NavLink>

              <span className="d-lg-flex mx-1">|</span>

              <NavLink to="#" onClick={() => CookieConsent.showPreferences()}>
                DEFINIÇÕES DE COOKIES
              </NavLink>
            </div>
          </Col>

          <Col xs={12}>
            <p className="text-small">
              Imagens meramente ilustrativas. Período de participação de 03/06 a
              30/08. *entregue em formato de cartão de crédito virtual.
              CERTIFICADO DE AUTORIZAÇÃO SPA/ME Nº 05.034192/2024 e SPA/ME Nº
              04.034205/2024
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
