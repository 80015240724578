import { RouterProvider } from "react-router-dom";
import AppProvider from "./providers/AppProvider";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { router } from "./router";

function App() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={import.meta.env.VITE_RECAPTCHA_KEY}>
      <AppProvider>
        <RouterProvider router={router}  />
      </AppProvider>
    </GoogleReCaptchaProvider>
  );
}

export default App;
