import { Image } from "react-bootstrap";
import LogoFullImage from "../assets/images/images/logo-full.png";
import { useEffect } from "react";
import { isBeforeRegisterPeriod } from "../utils";
import { useLocation, useNavigate } from "react-router-dom";

export function Component() {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    console.log(isBeforeRegisterPeriod());

    if (isBeforeRegisterPeriod()) {
      navigate("/em-breve");
    } else {
      if (pathname === "/em-breve") {
        navigate("/");
      }
    }
  }, [navigate, pathname]);

  return (
    <main className="align-items-center justify-content-center">
      <div className="d-flex flex-column">
        <Image width={240} src={LogoFullImage} className="mb-2" />
        <h1 className="bg-tint">Em breve</h1>
      </div>
    </main>
  );
}
