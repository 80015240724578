const createStore = () => {
  const initialData: any[] = [];

  function tryNotCatch(handler: any) {
    try {
      handler();
    } catch (err) {
      // Do nothing
    }
  }

  tryNotCatch(() => {
    Object.entries(localStorage).forEach(([key, value]) => {
      tryNotCatch(() => initialData.push([key, JSON.parse(value)]));
    });
  });

  const store = new Map<string, any>(initialData);

  function getItem(key: string) {
    return store.get(key);
  }

  function setItem(key: string, value: any, persist = true) {
    store.set(key, value);

    if (persist) {
      tryNotCatch(() => localStorage.setItem(key, JSON.stringify(value)));
    }
  }

  function removeItem(key: string) {
    store.delete(key);
    tryNotCatch(() => localStorage.removeItem(key));
  }

  return {
    setItem,
    getItem,
    removeItem,
  };
};

export default createStore();