import CookieConsent from "vanilla-cookieconsent";

const cookieConfig: CookieConsent.CookieConsentConfig = {
  guiOptions: {
    consentModal: {
      layout: "bar inline",
      position: "bottom",
      equalWeightButtons: true,
      flipButtons: false,
    },
    preferencesModal: {
      layout: "bar",
      position: "right",
      equalWeightButtons: true,
      flipButtons: false,
    },
  },
  categories: {
    necessary: {
      readOnly: true,
    },
    functionality: {},
    analytics: {},
    marketing: {},
  },
  language: {
    default: "pt",
    translations: {
      pt: {
        consentModal: {
          title: "Este site usa cookies",
          description:
            "Utilizamos cookies que o reconhecem e identificam como um usuário único, para garantir a melhor experiência de navegação, personalizar conteúdo e anúncios, e melhorar o desempenho do nosso site e serviços.",
          acceptAllBtn: "Aceitar todos",
          acceptNecessaryBtn: "Rejeitar todos",
          showPreferencesBtn: "Gerenciar preferências",
          footer: '<a href="/politica-privacidade">Política de privacidade</a>',
        },
        preferencesModal: {
          title: "Consentimentos de cookies",
          acceptAllBtn: "Aceitar",
          acceptNecessaryBtn: "Rejeitar todos",
          savePreferencesBtn: "Salvar preferências",
          closeIconLabel: "Fechar",
          serviceCounterLabel: "Service|Services",
          sections: [
            {
              title: "Uso de cookies",
              description:
                "Utilizamos cookies que o reconhecem e identificam como um usuário único, para garantir a melhor experiência de navegação, personalizar conteúdo e anúncios, e melhorar o desempenho do nosso site e serviços.",
            },
            {
              title: "Cookies obrigatórios <span class=\"pm__badge\">Sempre habilitado</span>",
              description: "São necessários por razões técnicas. Sem eles, este site pode não funcionar corretamente.",
              linkedCategory: "necessary"
            },
            {
                title: "Cookies de funcionalidade",
                description: "São necessários para funcionalidades específicas do site. Sem eles, algumas características podem ser desativadas.",
                linkedCategory: "functionality"
            },
            {
                title: "Cookies de análise",
                description: "Nos permitem analisar o uso do site e melhorar a experiência do visitante.",
                linkedCategory: "analytics"
            },
            {
                title: "Cookies de marketing",
                description: "Nos permitem personalizar sua experiência e enviar conteúdos e ofertas relevantes neste site e em outros sites.",
                linkedCategory: "marketing"
            },
            {
              title: "Mais informações",
              description:
                'Qualquer dúvida sobre as nossas políticas de cookies, por favor entre em contato através da seção <a class="cc__link" href="/fale-conosco">Fale Conosco</a>.',
            },
          ],
        },
      },
    },
    autoDetect: "browser",
  },
};

export default cookieConfig;