import { calcFirstChecker, calcSecondChecker } from "./calcChecker";
import {
  allDigitsAreEqual,
  hasCPFLength,
  generateFirstDigits,
  formatCPF,
} from "./index";

export default class CPF {
  static generate = ({ format }: { format?: boolean } = {}): string => {
    let firstNineDigits = "";

    do {
      firstNineDigits = generateFirstDigits();
    } while (allDigitsAreEqual(firstNineDigits));

    const firstChecker = calcFirstChecker(firstNineDigits);
    const secondChecker = calcSecondChecker(firstNineDigits + firstChecker);
    const generatedCPF = `${firstNineDigits}${firstChecker}${secondChecker}`;

    return formatCPF(generatedCPF, format);
  };

  static validate = (value: string): boolean => {
    if (typeof value !== "string") {
      return false;
    }

    const cleanCPF = String(value).replace(/[\s.-]/g, "");
    const firstNineDigits = cleanCPF.slice(0, 9);
    const checker = cleanCPF.slice(9, 11);

    if (!hasCPFLength(cleanCPF) || allDigitsAreEqual(cleanCPF)) {
      return false;
    }

    const checker1 = calcFirstChecker(firstNineDigits);
    const checker2 = calcSecondChecker(`${firstNineDigits}${checker1}`);

    return checker === `${checker1}${checker2}`;
  };
}
