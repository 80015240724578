import { Suspense, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import routes from "../configs/routes";
import useApi from "../hooks/useApi";
import storage from "../storage";
import { Modal, Button, Image } from "react-bootstrap";
import LogoFullImage from "../assets/images/images/logo-full.png";
import LineImage from "../assets/images/images/line.png";
import { isBeforeRegisterPeriod } from "../utils";

export default function RootLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const hashname = location.hash;

  const [isAdult, setIsAdult] = useState<boolean | null>(
    localStorage.getItem("isAdult") !== null
      ? localStorage.getItem("isAdult") === "true"
      : null,
  );

  const [extendedCampaign, setIsExtendedCampaign] = useState<boolean | null>(
    localStorage.getItem("extendedCampaign") !== null
      ? localStorage.getItem("extendedCampaign") === "true"
      : null,
  );


  const route = useMemo(
    () =>
      routes.find(
        (route) => pathname.startsWith(route.path) && route.path !== "/",
      ) ?? routes[0],
    [pathname],
  );

  const api = useApi();

  useEffect(() => {
    if (route) {
      const section =
        route.path === "/como-participar" ? "como-participar" : "";

      if (section) {
        const $section = document.querySelector(`#${section}`);
        $section?.scrollIntoView({ behavior: "smooth", block: "nearest" });
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      window.scrollTo(0, 0);
    }

    document.title = `${route?.title} - Promoção 120 horas`;
  }, [route]);

  useEffect(() => {
    api
      .getIP()
      .then((response) => {
        try {
          storage.setItem("ip_address", response.data.ip, false);
        } catch (err) {
          console.log(err);
        }
      })
      .catch((err) => {
        console.log(err);
        storage.setItem("ip_address", "");
      });
  }, [api]);

  useEffect(() => {
    if (hashname) {
      if (hashname.startsWith("#")) {
        const element = document.querySelector(hashname);

        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }

        return;
      }
    }
  }, [hashname]);

  useEffect(() => {
    if (isAdult === false) {
      navigate("/nao-adulto");
    } else {
      if (pathname === "/nao-adulto") {
        navigate("/");
      }
    }
  }, [isAdult, hashname, pathname, navigate]);

  useEffect(() => {
    console.log(isBeforeRegisterPeriod());

    if (isBeforeRegisterPeriod()) {
      navigate("/em-breve");
    } else {
      if (pathname === "/em-breve") {
        navigate("/");
      }
    }
  }, [navigate, pathname]);

  return (
    <>
      <Header />
      <main>
        <Suspense key={location.key} fallback={<h1>Carregando...</h1>}>
          <Outlet />
        </Suspense>
      </main>
      <Footer />
      <Modal className="custom-modal" show={extendedCampaign === null}>
          <Modal.Body className="d-flex align-items-center justify-content-center flex-column">
            <span className="h3 text-center">ATENÇÃO, A PROMOÇÃO 120 HORAS FOI ESTENDIDA E AGORA TERMINA EM 30/08/2024.</span>

            <div className="d-flex">
              <Button
                onClick={() => {
                  localStorage.setItem("extendedCampaign", "true");
                  setIsExtendedCampaign(true);
                  navigate("/#como-participar")
                }}
                type="submit"
                variant="primary"
                className="button btn-tint mx-auto"
              >
                PARTICIPE!
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      <Modal className="custom-modal" show={isAdult === null}>
          <Modal.Body className="d-flex align-items-center justify-content-center flex-column">
            <span className="h3">Bem-vindo a</span>

            <Image src={LogoFullImage} width={240} />

            <Image className="my-4" src={LineImage} fluid />

            <span className="h3">É MAIOR DE IDADE?</span>

            <div className="d-flex">
              <Button
                onClick={() => {
                  localStorage.setItem("isAdult", "true");
                  setIsAdult(true);
                }}
                type="submit"
                variant="primary"
                className="button btn-tint mx-auto"
              >
                SIM
              </Button>

              <Button
                onClick={() => {
                  localStorage.setItem("isAdult", "false");
                  setIsAdult(false);
                }}
                type="submit"
                variant="primary"
                className="button btn-tint mx-auto"
              >
                NÃO
              </Button>
            </div>
          </Modal.Body>
        </Modal>
    </>
  );
}

